import React from 'react';
import IDiscountListProps from './IDiscountListProps';
import './DiscountList.css'
import IDiscountListState from './IDiscountListState';
import DiscountDetails from '../DiscountDetails/DiscountDetails';
import Discount from '../../Models/Discount';

class DiscountList extends React.Component<IDiscountListProps, IDiscountListState> {
    constructor(props: IDiscountListProps) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.showDiscountDetails = this.showDiscountDetails.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteDiscount = this.deleteDiscount.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.loadDiscounts = this.loadDiscounts.bind(this);
    }

    toggleMenu() {
        document.querySelector('.navbar-menu')?.classList.toggle('is-active');
    }

    showDiscountDetails(e: React.MouseEvent<HTMLButtonElement>) {
        const target = e.target as HTMLButtonElement;
        var discountId = target.dataset.discountId != undefined ? parseInt(target.dataset.discountId) : 0;

        if (discountId > 0) {
            var discount = this.state.discountList?.find(x => x.id === discountId);
            if (discount === undefined) {
                discount = new Discount();
            }

            this.setState({
                selectedDiscount: discount
            }, () => {
                document.getElementById("add-edit-discount")?.classList.add('is-active');
            });
        }
        else {
            this.setState({
                selectedDiscount: new Discount()
            }, () => {
                document.getElementById("add-edit-discount")?.classList.add('is-active');
            });
        }
    }

    async onUpdate() {
        this.loadDiscounts();
        this.closeModal();
    }

    async deleteDiscount() {
        var discountId = this.state.selectedDiscount != undefined ? this.state.selectedDiscount.id : 0;
        this.props.apiServiceInstance.InvokeDiscountDeleteApiCall(discountId).then(() => {
            this.loadDiscounts();
            this.closeModal();
        }).catch((error: any) => {
            console.log(error.message);
            document.getElementById("delete-error-message")?.classList.remove('is-hidden');
        });
    }

    confirmDelete(e: React.MouseEvent<HTMLButtonElement>) {
        document.getElementById("confirm-delete-discount")?.classList.add('is-active');
        const target = e.target as HTMLButtonElement;
        var discountId = target.dataset.discountId != undefined ? parseInt(target.dataset.discountId) : 0;
        if (discountId > 0) {
            var discount = this.state.discountList?.find(x => x.id === discountId);
            if (discount === undefined) {
                discount = new Discount();
            }
            this.setState({
                selectedDiscount: discount
            });
        }
    }

    closeModal() {
        this.setState({
            selectedDiscount: new Discount()
        }, () => {
            var modals = document.getElementsByClassName("modal");
            for (var i = 0; i < modals.length; i++) {
                modals[i].classList.remove('is-active');
            }
        });
    }
    
    async componentDidMount() {
        window.onkeydown = (ev: KeyboardEvent): any => {
            if (ev.key === "Escape") {
                this.closeModal();
            }
        }
        this.loadDiscounts();
    }

    async loadDiscounts() {
        this.props.apiServiceInstance.InvokeTariffListApiCall().then((response: any) => {
            this.setState({
                tariffList: response
            }, () => {
                this.props.apiServiceInstance.InvokeLocalPopListApiCall().then((response: any) => {
                    this.setState({
                        localPopList: response
                    }, () => {
                        this.props.apiServiceInstance.InvokeDiscountListApiCall().then((response: any) => {
                            this.setState({
                                discountList: response,
                                selectedDiscount: new Discount()
                            });
                        }).catch((error: any) => {
                            console.log(error.message);
                        });
                    });
                }).catch((error: any) => {
                    console.log(error.message);
                });
            });
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    render() {
        if (this.state?.discountList != undefined) {
            return (
                <div className="discounts">
                    <h1 className="heading">Discounts</h1>
                    <button className="button is-primary mb-3 is-pulled-left" onClick={this.showDiscountDetails} data-discount-id={0}>Add Discount</button>
                    <div className='clear'></div>
                    <div className="table-container">
                        <table className="table is-bordered is-striped is-narrow is-hoverable">
                            <thead>
                                <tr>
                                    <th>Discount Code</th>
                                    <th>Valid From</th>
                                    <th>Valid To</th>
                                    <th>Single Use?</th>
                                    <th>Date Used</th>
                                    <th>Is Active?</th>
                                    <th>Description</th>
                                    <th>Is Public?</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.discountList.map(discount => (
                                <tr key={discount.id}>
                                    <td>{discount.discountCode}</td>
                                    <td>{discount.validFrom && new Date(discount.validFrom).toLocaleDateString()}</td>
                                    <td>{discount.validTo && new Date(discount.validTo).toLocaleDateString()}</td>
                                    <td>{discount.singleUse ? String.fromCharCode(10004) : String.fromCharCode(10006)}</td>
                                    <td>{discount.dateUsed && new Date(discount.dateUsed).toLocaleDateString()}</td>
                                    <td>{discount.isActive ? String.fromCharCode(10004) : String.fromCharCode(10006)}</td>
                                    <td>{discount.description}</td>
                                    <td>{discount.isPublic ? String.fromCharCode(10004) : String.fromCharCode(10006)}</td>
                                    <td>
                                        <button className="button is-ghost" onClick={this.showDiscountDetails} data-discount-id={discount.id}>Edit</button>
                                        <button className="button is-ghost" onClick={this.confirmDelete} data-discount-id={discount.id}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    
                    <DiscountDetails discountDetails={this.state.selectedDiscount} localPopList={this.state.localPopList} tariffList={this.state.tariffList} apiServiceInstance={this.props.apiServiceInstance}
                        authServiceInstance={this.props.authServiceInstance} onClose={this.closeModal} onSubmit={this.onUpdate} />

                    <div id="confirm-delete-discount" className="modal">
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Confirm Delete</p>
                                <button className="delete" aria-label="close" onClick={this.closeModal}></button>
                            </header>
                            <section className="modal-card-body">
                                <p>Discount Code: {this.state.selectedDiscount?.discountCode}</p>
                                <p>Description: {this.state.selectedDiscount?.description}</p>
                                <p id="delete-error-message" className="has-text-danger mt-3 is-hidden">Unable to delete. Please try again later.</p>
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button is-danger" onClick={this.deleteDiscount}>Delete</button>
                                <button className="button" onClick={this.closeModal}>Cancel</button>
                            </footer>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return(
                <div className="fa-2x">
                    <h1 className="heading">Discounts</h1>
                    <i className="fas fa-spinner fa-pulse"></i>
                </div>
            );
        }
    }
}

export default DiscountList;