class DiscountErrors {
    discountCode: string = '';
    validFrom: string = '';
    validTo: string = '';
    singleUse: string = '';
    setupDiscountGbp:  string = '';
    routerDiscountGbp:  string = '';
    monthlyDiscountGbp:  string = '';
    monthlyDiscountDuration:  string = '';
    isActive:  string = '';
    validTariffs: string = '';
    validLocalPops: string = '';
    minimumTerm:  string = '';
    description: string = '';
    missingDiscount: boolean = false;
    isPublic:  string = '';
}

export default DiscountErrors;