import React from 'react';
import INotSignedInProps from './INotSignedInProps';

class NotSignedIn extends React.Component<INotSignedInProps, {}> {
    render() {
        return (
            <div className="columns is-mobile is-centered is-vcentered">
                <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                    <h1 className="title is-4 mt-5">1310 Discount Management</h1>
                    <button className="button is-primary" onClick={this.props.loginButtonClicked}>Log In</button>
                </div>
            </div>
        );
    }
}

export default NotSignedIn;