import React from 'react';
import './Tools.css'
import IToolsProps from './IToolsProps';
import IToolsState from './IToolsState';
import LocalPop from '../../Models/LocalPop';

class Tools extends React.Component<IToolsProps, IToolsState> {
    constructor(props: IToolsProps) {
        super(props);

        this.state = {
            selectedLocalPops: '',
            localPopList: new Array<LocalPop>()
        };

        this.handleLocalPopsChange = this.handleLocalPopsChange.bind(this);
        this.loadLocalPops = this.loadLocalPops.bind(this);

        this.handleOfcomClick = this.handleOfcomClick.bind(this);
        this.handleAddressClick = this.handleAddressClick.bind(this);
        this.handleWaitlistClick = this.handleWaitlistClick.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
        this.handleServiceExpiryClick = this.handleServiceExpiryClick.bind(this);
    }

    async componentDidMount() {
        this.loadLocalPops();
    }

    async loadLocalPops() {
        this.props.apiServiceInstance.InvokeLocalPopListApiCall().then((response: any) => {
            this.setState({
                localPopList: response
            });
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    handleLocalPopsChange(e: React.ChangeEvent<HTMLInputElement>) {
        var selectedLocalPopsArray = this.state.selectedLocalPops.split(',');
        
        if (selectedLocalPopsArray?.includes(e.target.value.toString())) {
            selectedLocalPopsArray = selectedLocalPopsArray.filter(function(x) { return x !== e.target.value.toString() });
            
            this.setState(prevState => ({
                selectedLocalPops: selectedLocalPopsArray.join(',')
            }));
        }
        else {
            this.setState(prevState => ({
                selectedLocalPops: this.state.selectedLocalPops !== '' ? this.state.selectedLocalPops + ',' + e.target.value : e.target.value
            }));
        }
    }

    async handleOfcomClick() {
        this.props.apiServiceInstance.InvokeOfcomApiCall().then((response: any) => {
            if (response.status == 202) {
                document.getElementById("ofcom-button")?.classList.add('is-hidden');
                document.getElementById("ofcom-error-message")?.classList.add('is-hidden');
                document.getElementById("ofcom-ok-message")?.classList.remove('is-hidden');
            }
            else {
                document.getElementById("ofcom-button")?.classList.remove('is-hidden');
                document.getElementById("ofcom-error-message")?.classList.remove('is-hidden');
                document.getElementById("ofcom-ok-message")?.classList.add('is-hidden');
            }
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    async handleAddressClick() {
        if (this.state.selectedLocalPops == '') {
            document.getElementById("address-validation-message")?.classList.remove('is-hidden');
        }
        else {
            document.getElementById("address-validation-message")?.classList.add('is-hidden');

            this.props.apiServiceInstance.InvokeAddressApiCall(this.state.selectedLocalPops).then((response: any) => {
                if (response.status == 202) {
                    document.getElementById("address-button")?.classList.add('is-hidden');
                    document.getElementById("address-error-message")?.classList.add('is-hidden');
                    document.getElementById("address-ok-message")?.classList.remove('is-hidden');
                }
                else {
                    console.log(response.body);
                    document.getElementById("address-button")?.classList.remove('is-hidden');
                    document.getElementById("address-error-message")?.classList.remove('is-hidden');
                    document.getElementById("address-ok-message")?.classList.add('is-hidden');
                }
            }).catch((error: any) => {
                console.log(error.message);
            });
        }
    }

    async handleWaitlistClick() {
        this.props.apiServiceInstance.InvokeWaitlistApiCall(this.props.email).then((response: any) => {
            if (response.status == 202) {
                document.getElementById("waitlist-button")?.classList.add('is-hidden');
                document.getElementById("waitlist-error-message")?.classList.add('is-hidden');
                document.getElementById("waitlist-ok-message")?.classList.remove('is-hidden');
            }
            else {
                console.log(response.body);
                document.getElementById("waitlist-button")?.classList.remove('is-hidden');
                document.getElementById("waitlist-error-message")?.classList.remove('is-hidden');
                document.getElementById("waitlist-ok-message")?.classList.add('is-hidden');
            }
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    async handleUpgradeClick() {
        this.props.apiServiceInstance.InvokeUpgradeApiCall(this.props.email).then((response: any) => {
            if (response.status == 202) {
                document.getElementById("upgrade-button")?.classList.add('is-hidden');
                document.getElementById("upgrade-error-message")?.classList.add('is-hidden');
                document.getElementById("upgrade-ok-message")?.classList.remove('is-hidden');
            }
            else {
                console.log(response.body);
                document.getElementById("upgrade-button")?.classList.remove('is-hidden');
                document.getElementById("upgrade-error-message")?.classList.remove('is-hidden');
                document.getElementById("upgrade-ok-message")?.classList.add('is-hidden');
            }
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    async handleServiceExpiryClick() {
        this.props.apiServiceInstance.InvokeServiceExpiryApiCall(this.props.email).then((response: any) => {
            if (response.status == 202) {
                document.getElementById("service-expiry-button")?.classList.add('is-hidden');
                document.getElementById("service-expiry-error-message")?.classList.add('is-hidden');
                document.getElementById("service-expiry-ok-message")?.classList.remove('is-hidden');
            }
            else {
                console.log(response.body);
                document.getElementById("service-expiry-button")?.classList.remove('is-hidden');
                document.getElementById("service-expiry-error-message")?.classList.remove('is-hidden');
                document.getElementById("service-expiry-ok-message")?.classList.add('is-hidden');
            }
        }).catch((error: any) => {
            console.log(error.message);
        });
    }

    render() {
        return (
            <div>
                <h1 className="heading">Tools</h1>

                <div className='mb-5'>
                    <h2 className="sub-heading">Ofcom F01 Report</h2>
                    <p className='mb-2'>We are required to submit this report to Ofcom on a quarterly basis. It will be posted to the <strong>#ofcom</strong> channel in Slack when ready.</p>
                    <button id="ofcom-button" className="button is-primary" onClick={this.handleOfcomClick}>Request Ofcom Report</button>
                    <label id="ofcom-error-message mt-2" className="label has-text-danger is-hidden">OFCOM Report request failed. Please try again later.</label>
                    <label id="ofcom-ok-message mt-2" className="label is-hidden">OFCOM Report request accepted. Report will be posted to Slack when complete (usually within the next 30 minutes).</label>
                </div>

                <div className='mb-5'>
                    <h2 className="sub-heading">Waitlist Report</h2>
                    <p className='mb-2'>
                        This report contains information on all waitlist entries and any products we can currently provide to those locations.
                        <br/>
                        It will be emailed to {this.props.email} when ready.
                        <br />
                        In order to comply with GDPR, please ensure the report is only used for its intended purpose and is deleted after use.
                    </p>
                    <button id="waitlist-button mb-2" className="button is-primary" onClick={this.handleWaitlistClick}>Request Waitlist Report</button>
                    <label id="waitlist-error-message" className="label has-text-danger is-hidden">Waitlist Report request failed. Please try again later.</label>
                    <label id="waitlist-ok-message" className="label is-hidden">Waitlist Report request accepted. Report will be emailed to you when complete (usually within the next 30 minutes).</label>
                </div>

                <div className='mb-5'>
                    <h2 className="sub-heading">Service Upgrade Report</h2>
                    <p className='mb-2'>This report contains information on current customers and any potential service upgrades we could offer them.
                        <br/>
                        It will be emailed to {this.props.email} when ready.
                        <br />
                        In order to comply with GDPR, please ensure the report is only used for its intended purpose and is deleted after use.
                    </p>
                    <button id="upgrade-button mb-2" className="button is-primary" onClick={this.handleUpgradeClick}>Request Upgrade Report</button>
                    <label id="upgrade-error-message" className="label has-text-danger is-hidden">Upgrade Report request failed. Please try again later.</label>
                    <label id="upgrade-ok-message" className="label is-hidden">Upgrade Report request accepted. Report will be emailed to you when complete (usually within the next 30 minutes).</label>
                </div>

                <div className='mb-5'>
                    <h2 className="sub-heading">Service Expiry Report</h2>
                    <p className='mb-2'>This report contains information on customers whose service contracts are due to expire in the next 90 days.
                        <br/>
                        It will be emailed to {this.props.email} when ready.
                        <br />
                        In order to comply with GDPR, please ensure the report is only used for its intended purpose and is deleted after use.
                    </p>
                    <button id="service-expiry-button mb-2" className="button is-primary" onClick={this.handleServiceExpiryClick}>Request Service Expiry Report</button>
                    <label id="service-expiry-error-message" className="label has-text-danger is-hidden">Service Expiry Report request failed. Please try again later.</label>
                    <label id="service-expiry-ok-message" className="label is-hidden">Service Expiry Report request accepted. Report will be emailed to you when complete (usually within the next 30 minutes).</label>
                </div>

                <div className='mb-5'>
                    <h2 className="sub-heading">Local POP Address Report</h2>
                    <p className='mb-2'>
                        This report will contain addresses for any selected local POPs.
                        <br/>
                        Please be aware that if we have not previously requested addresses for a selected local POP, there will be a cost associated with the request.
                        <br />
                        (Ideal Postcodes charge us £50 inc. VAT per 1100 address lookups).
                        <br />
                        <p className='mb-2'>The report will be posted to the <strong>#marketing</strong> channel in Slack when ready.</p>
                    </p>
                    <div className="field scrollable-div">
                        {
                            this.state.localPopList?.map(({id, popName}) => {
                            return (
                                <div className="control" key={id}>
                                    <label className="checkbox mr-1">{popName}</label>
                                    <input name="localPops" type="checkbox"
                                        value={id} onChange={this.handleLocalPopsChange} checked={this.state.selectedLocalPops.split(',').includes(id)} />
                                </div>
                            );
                        })}
                    </div>

                    <label id="address-validation-message" className="label has-text-danger is-hidden">You must select at least one Local POP</label>
                    <button id="address-button mb-2" className="button is-primary" onClick={this.handleAddressClick}>Request Local POPs Address Report</button>
                    <label id="address-error-message" className="label has-text-danger is-hidden">Address Report request failed. Please try again later.</label>
                    <label id="address-ok-message" className="label is-hidden">Address Report request accepted. Report will be posted to Slack when complete (usually within the next 30 minutes).</label>
                </div>
            </div>
        );
    }
}

export default Tools;