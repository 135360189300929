import React from 'react';
import IDiscountDetailsProps from './IDiscountDetailsProps';
import './DiscountDetails.css'
import IDiscountDetailsState from './IDiscountDetailsState';
import DiscountErrors from '../../Models/DiscountErrors';
import Discount from '../../Models/Discount';

class DiscountDetails extends React.Component<IDiscountDetailsProps, IDiscountDetailsState> {
    constructor(props: IDiscountDetailsProps) {
        super(props);
        
        this.state = {
            discountDetails: new Discount(),
            errors: new DiscountErrors()
        };

        this.handleDiscountCodeChange = this.handleDiscountCodeChange.bind(this);
        this.handleValidFromChange = this.handleValidFromChange.bind(this);
        this.handleValidToChange = this.handleValidToChange.bind(this);
        this.handleSingleUseChange = this.handleSingleUseChange.bind(this);
        this.handleSetupDiscountGbpChange = this.handleSetupDiscountGbpChange.bind(this);
        this.handleRouterDiscountGbpChange = this.handleRouterDiscountGbpChange.bind(this);
        this.handleMonthlyDiscountGbpChange = this.handleMonthlyDiscountGbpChange.bind(this);
        this.handleMonthlyDiscountDurationChange = this.handleMonthlyDiscountDurationChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleValidTariffsChange = this.handleValidTariffsChange.bind(this);
        this.handleValidLocalPopsChange = this.handleValidLocalPopsChange.bind(this);
        this.handleMinimumTermChange = this.handleMinimumTermChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDiscountCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                discountCode: e.target.value
            }
        }));
    }
    
    handleValidFromChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                validFrom: e.target.value
            }
        }));
    }

    handleValidToChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                validTo: e.target.value
            }
        }));
    }

    handleSingleUseChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                singleUse: e.target.checked === true,
                isPublic: e.target.checked === true ? false : this.state.discountDetails.isPublic
            }
        }));
    }

    handleSetupDiscountGbpChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                setupDiscountGbp: Number(e.target.value)
            }
        }));
    }

    handleRouterDiscountGbpChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                routerDiscountGbp: Number(e.target.value)
            }
        }));
    }

    handleMonthlyDiscountGbpChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                monthlyDiscountGbp: Number(e.target.value)
            }
        }));
    }

    handleMonthlyDiscountDurationChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                monthlyDiscountDuration: Number(e.target.value)
            }
        }));
    }

    handleIsActiveChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                isActive: e.target.checked === true
            }
        }));
    }

    handleIsPublicChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                isPublic: e.target.checked === true
            }
        }));
    }

    handleValidTariffsChange(e: React.ChangeEvent<HTMLInputElement>) {
        let discountDetails = this.state.discountDetails;

        var selectedTariffs = discountDetails.validTariffs.split(',');

        if (selectedTariffs?.includes(e.target.value.toString())) {
            selectedTariffs = selectedTariffs.filter(function(x) { return x !== e.target.value.toString() });

            this.setState(prevState => ({
                discountDetails: {
                    ...prevState.discountDetails,
                    validTariffs: selectedTariffs.join(',')
                }
            }));
        }
        else {
            this.setState(prevState => ({
                discountDetails: {
                    ...prevState.discountDetails,
                    validTariffs: discountDetails?.validTariffs !== '' ? discountDetails["validTariffs"] + ',' + e.target.value : e.target.value
                }
            }));
        }
    }

    handleValidLocalPopsChange(e: React.ChangeEvent<HTMLInputElement>) {
        let discountDetails = this.state.discountDetails;
        
        var selectedLocalPops = discountDetails.validLocalPops.split(',');
        
        if (selectedLocalPops?.includes(e.target.value.toString())) {
            selectedLocalPops = selectedLocalPops.filter(function(x) { return x !== e.target.value.toString() });
            
            this.setState(prevState => ({
                discountDetails: {
                    ...prevState.discountDetails,
                    validLocalPops: selectedLocalPops.join(',')
                }
            }));
        }
        else {
            this.setState(prevState => ({
                discountDetails: {
                    ...prevState.discountDetails,
                    validLocalPops: discountDetails?.validLocalPops !== '' ? discountDetails["validLocalPops"] + ',' + e.target.value : e.target.value
                }
            }));
        }
    }

    handleMinimumTermChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                minimumTerm: Number(e.target.value)
            }
        }));
    }

    handleDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState(prevState => ({
            discountDetails: {
                ...prevState.discountDetails,
                description: e.target.value
            }
        }));
    }

    async handleSubmit() {
        if (this.handleValidation()) {
            if (this.state.discountDetails?.id > 0) {
                this.props.apiServiceInstance.InvokeDiscountUpdateApiCall(this.state.discountDetails).then((response: any) => {
                    this.props.onSubmit();
                }).catch((error: any) => {
                    console.log(error.message);
                    document.getElementById("update-error-message")?.classList.remove('is-hidden');
                });
            }
            else {
                this.props.apiServiceInstance.InvokeDiscountCreateApiCall(this.state.discountDetails).then((response: any) => {
                    this.props.onSubmit();
                }).catch((error: any) => {
                    console.log(error.message);
                    document.getElementById("add-error-message")?.classList.remove('is-hidden');
                });
            }
        }
    }

    handleValidation() {
        let discountDetails = this.state.discountDetails;
        let errors = this.state.errors;
        let formIsValid = true;

        if(discountDetails?.discountCode === '') {
           formIsValid = false;
           errors.discountCode = "Please enter a discount code";
        } else {
            errors.discountCode = "";
        }
        
        if(discountDetails?.description === '') {
            formIsValid = false;
            errors.description = "Please enter a description";
        } else {
             errors.description = "";
        }

        if(discountDetails?.validTariffs === '') {
            formIsValid = false;
            errors.validTariffs = "Please select at least one tariff";
        } else {
             errors.validTariffs = "";
        }
        
        if(discountDetails?.setupDiscountGbp === 0 
            && discountDetails?.routerDiscountGbp === 0 
            && discountDetails?.monthlyDiscountGbp === 0) {
            formIsValid = false;
            errors.missingDiscount = true;
        } else {
             errors.missingDiscount = false;
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    async componentDidUpdate(previousProps: IDiscountDetailsProps) {
        document.getElementById("update-error-message")?.classList.add('is-hidden');
        document.getElementById("add-error-message")?.classList.add('is-hidden');
        if (previousProps.discountDetails !== this.props.discountDetails || this.state.discountDetails === undefined) {
            this.setState({
                discountDetails: this.props.discountDetails,
                errors: new DiscountErrors()
            });
        }
    }
    
    render() {
        if (this.state?.discountDetails != undefined) {
            return (
                <div id="add-edit-discount" className="modal">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.state.discountDetails.id > 0 ? "Edit": "Add"} Discount</p>
                            <button className="delete" aria-label="close" onClick={this.props.onClose}></button>
                        </header>
                        <section className="modal-card-body">
                            <div className='columns'>
                                <div className='column'>
                                    <div className="field">
                                        <label className="label">Discount code *</label>
                                        <div className="control">
                                            <input className={`input ${this.state.errors.discountCode.length > 0 ? "is-danger" : ""}`} id="discountCode" type="text" 
                                                value={this.state.discountDetails.discountCode == null ? '' : this.state.discountDetails.discountCode} onChange={this.handleDiscountCodeChange} />
                                        </div>
                                        {this.state.errors.discountCode.length > 0 && <label className='has-text-danger'>{this.state.errors.discountCode}</label>}
                                    </div>

                                    <div className="field">
                                        <label className="label">Valid from</label>
                                        <div className="control">
                                            <input className={`input ${this.state.errors.validFrom.length > 0 ? "is-danger" : ""}`} id="validFrom" type="date"
                                                value={this.state.discountDetails.validFrom == null ? '' : new Date(this.state.discountDetails.validFrom).toISOString().split('T')[0]} onChange={this.handleValidFromChange} />
                                        </div>
                                        {this.state.errors.validFrom.length > 0 && <label className='has-text-danger'>{this.state.errors.validFrom}</label>}
                                    </div>

                                    <div className="field">
                                        <label className="label">Valid to</label>
                                        <div className="control">
                                            <input className={`input ${this.state.errors.validTo.length > 0 ? "is-danger" : ""}`} id="validTo" type="date"
                                                value={this.state.discountDetails.validTo == null ? '' : new Date(this.state.discountDetails.validTo).toISOString().split('T')[0]} onChange={this.handleValidToChange} />
                                        </div>
                                        {this.state.errors.validTo.length > 0 && <label className='has-text-danger'>{this.state.errors.validTo}</label>}
                                    </div>

                                    <label className="label">Setup discount (EX-VAT) *</label>
                                    <div className="field has-addons mb-0">
                                        <p className="control"><span className="button is-static">£</span></p>
                                        <div className="control is-expanded">
                                            <input className={`input ${this.state.errors.missingDiscount ? "is-danger" : ""}`} id="setupDiscountGbp" type="number" min="0"
                                                value={this.state.discountDetails.setupDiscountGbp == null ? '' : this.state.discountDetails.setupDiscountGbp} onChange={this.handleSetupDiscountGbpChange} />
                                        </div>
                                    </div>
                                    {this.state.errors.missingDiscount && <p className='has-text-danger'>Please enter at least one discount value</p>}

                                    <label className="label mt-3">Router discount (EX-VAT) *</label>
                                    <div className="field has-addons mb-0">
                                        <p className="control"><span className="button is-static">£</span></p>
                                        <div className="control is-expanded">
                                            <input className={`input ${this.state.errors.missingDiscount ? "is-danger" : ""}`} id="routerDiscountGbp" type="number" min="0"
                                                value={this.state.discountDetails.routerDiscountGbp == null ? '' : this.state.discountDetails.routerDiscountGbp} onChange={this.handleRouterDiscountGbpChange} />
                                        </div>
                                    </div>
                                    {this.state.errors.missingDiscount && <p className='has-text-danger'>Please enter at least one discount value</p>}

                                    <label className="label mt-3">Monthly discount (EX-VAT) *</label>
                                    <div className="field has-addons mb-0">
                                        <p className="control"><span className="button is-static">£</span></p>
                                        <div className="control is-expanded">
                                            <input className={`input ${this.state.errors.missingDiscount ? "is-danger" : ""}`} id="monthlyDiscountGbp" type="number" min="0"
                                                value={this.state.discountDetails.monthlyDiscountGbp == null ? '' : this.state.discountDetails.monthlyDiscountGbp} onChange={this.handleMonthlyDiscountGbpChange} />
                                        </div>
                                    </div>
                                    {this.state.errors.missingDiscount && <p className='has-text-danger'>Please enter at least one discount value</p>}

                                    <label className="label mt-3 mb-0">Monthly discount duration</label>
                                    <p className="help mb-3">Leave blank for lifetime discount or if N/A</p>
                                    <div className="field has-addons">
                                        <div className="control is-expanded">
                                            <input className={`input ${this.state.errors.monthlyDiscountDuration.length > 0 ? "is-danger" : ""}`} id="monthlyDiscountDuration" type="number" min="1"
                                                value={this.state.discountDetails.monthlyDiscountDuration == null ? '' : this.state.discountDetails.monthlyDiscountDuration} onChange={this.handleMonthlyDiscountDurationChange} />
                                        </div>
                                        <div className="control">
                                            <span className="button is-static">months</span>
                                        </div>
                                        {this.state.errors.monthlyDiscountDuration.length > 0 && <label className='has-text-danger'>{this.state.errors.monthlyDiscountDuration}</label>}
                                    </div>
                                    
                                    <div className="field">
                                        <label className="checkbox label">Is active?
                                            <input className={`${this.state.errors.isActive.length > 0 ? "is-danger ml-3" : "ml-3"}`} id="isActive" type="checkbox" 
                                                checked={this.state.discountDetails.isActive == null ? false : this.state.discountDetails.isActive} onChange={this.handleIsActiveChange} />
                                        </label>
                                        <p className="help mb-3">If unticked, discount code will not be accepted on signup</p>
                                        {this.state.errors.isActive.length > 0 && <label className='has-text-danger'>{this.state.errors.isActive}</label>}
                                    </div>
                                    <hr />
                                    <div className="field">
                                        <label className="checkbox label">Single use?
                                            <input className={`${this.state.errors.singleUse.length > 0 ? "is-danger ml-3" : "ml-3"}`} id="singleUse" type="checkbox" 
                                                checked={this.state.discountDetails.singleUse == null ? false : this.state.discountDetails.singleUse} onChange={this.handleSingleUseChange} />
                                        </label>
                                        {this.state.errors.singleUse.length > 0 && <label className='has-text-danger'>{this.state.errors.singleUse}</label>}
                                    </div>
                                    <hr />
                                    <div className="field">
                                        <label className="checkbox label">Is public?
                                            <input className={`${this.state.errors.isPublic.length > 0 ? "is-danger ml-3" : "ml-3"}`} id="isPublic" type="checkbox" 
                                                disabled={this.state.discountDetails.singleUse != null && this.state.discountDetails.singleUse}
                                                checked={this.state.discountDetails.isPublic == null ? false : this.state.discountDetails.isPublic} onChange={this.handleIsPublicChange} />
                                        </label>
                                        <p className="help mb-3">If ticked, discount will be proactively offered on signup<br />(does not apply to single-use discount codes)</p>
                                        {this.state.errors.isPublic.length > 0 && <label className='has-text-danger'>{this.state.errors.isPublic}</label>}
                                    </div>
                                    <hr />
                                    <label className="label mb-0">Valid tariffs *</label>
                                    <div className={`field${this.state.errors.validTariffs.length > 0 ? " is-danger" : ""} mb-0 py-1`}>
                                        {this.props.tariffList.map(({id, service_name}) => {
                                            return (
                                                <div className="control" key={id}>
                                                    <label className="checkbox mr-1">{service_name}</label>
                                                    <input name="validTariffs" type="checkbox"
                                                        value={id} onChange={this.handleValidTariffsChange} checked={this.state.discountDetails.validTariffs.split(',').includes(id)} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {this.state.errors.validTariffs.length > 0 && <p className='has-text-danger mt-1'>{this.state.errors.validTariffs}</p>}

                                    <label className="label mb-0 mt-3">Valid local pops</label>
                                    <p className="help">(If no local pops are selected, discount code will be valid for any location)</p>
                                    <div className={`field${this.state.errors.validLocalPops.length > 0 ? " is-danger" : ""} mb-0 py-1`}>
                                        {
                                            this.props.localPopList.map(({id, popName}) => {
                                            return (
                                                <div className="control" key={id}>
                                                    <label className="checkbox mr-1">{popName}</label>
                                                    <input name="validLocalPops" type="checkbox"
                                                        value={id} onChange={this.handleValidLocalPopsChange} checked={this.state.discountDetails.validLocalPops.split(',').includes(id)} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {this.state.errors.validLocalPops.length > 0 && <p className='has-text-danger mt-1'>{this.state.errors.validLocalPops}</p>}

                                    <label className="label mb-0 mt-3">Minimum term</label>
                                    <p className="help mb-3">Leave blank to use tariff minimum term</p>
                                    <div className="field has-addons">
                                        <div className="control is-expanded">
                                            <input className={`input ${this.state.errors.minimumTerm.length > 0 ? "is-danger" : ""}`} id="minimumTerm" type="number" min="1"
                                                value={this.state.discountDetails.minimumTerm == null ? '' : this.state.discountDetails.minimumTerm} onChange={this.handleMinimumTermChange} />
                                        </div>
                                        <div className="control">
                                            <span className="button is-static">months</span>
                                        </div>
                                        {this.state.errors.minimumTerm.length > 0 && <label className='has-text-danger'>{this.state.errors.minimumTerm}</label>}
                                    </div>

                                    <div className="field">
                                        <label className="label">Description *</label>
                                        <p className="help mb-3">Will be visible in Splynx and on customer invoice</p>
                                        <div className="control">
                                            <input className={`input ${this.state.errors.description.length > 0 ? "is-danger" : ""}`} id="description" type="text" 
                                                value={this.state.discountDetails.description == null ? '' : this.state.discountDetails.description} onChange={this.handleDescriptionChange} />
                                        </div>
                                        {this.state.errors.description.length > 0 && <label className='has-text-danger'>{this.state.errors.description}</label>}
                                    </div>

                                    <p id="update-error-message" className="has-text-danger mt-3 is-hidden">Unable to update. Please try again later.</p>
                                    <p id="add-error-message" className="has-text-danger mt-3 is-hidden">Unable to add. Please try again later.</p>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-success" onClick={this.handleSubmit}>Save changes</button>
                            <button className="button" onClick={this.props.onClose}>Cancel</button>
                        </footer>
                    </div>
                </div>
            );
        }
        else return null;
    }
}

export default DiscountDetails;