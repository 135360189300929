import './App.scss';
import React from 'react';
import IAppProps from './IAppProps';
import IAppState from './IAppState';
import Tools from '../Tools/Tools';
import DiscountList from '../DiscountList/DiscountList';
import NotSignedIn from '../NotSignedIn/NotSignedIn';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';

class App extends React.Component<IAppProps, IAppState> {

  constructor(props: IAppProps) {
      super(props);
  }

  invokeSignInEvent = () => {
      this.props.authServiceInstance.SignIn();
  }

  invokeSignOutEvent = () => {
      this.props.authServiceInstance.SignOut();
  }

  render() {
      if (this.props.authServiceInstance.account) {
        console.log(this.props.authServiceInstance.account);
        return (
            <Router>
                <div className="App">
                    <div className="columns is-mobile mx-3">
                        <div className="column is-full">
                            
                            <nav className="navbar" role="navigation" aria-label="main navigation">
                                <div className="navbar-brand">
                                    <NavLink className="navbar-item" to="/">
                                        <strong>Discounts</strong>
                                    </NavLink>
                                    <NavLink className="navbar-item" to="/tools">
                                        <strong>Tools</strong>
                                    </NavLink>
                                    <p className="navbar-item">
                                    Logged in as: {this.props.authServiceInstance.account?.username}
                                    </p>
                                    <a className="navbar-item button is-ghost my-3" onClick={this.invokeSignOutEvent}>Log Out</a>
                                </div>
                            </nav>

                            <Routes>
                                <Route path='/' element={<DiscountList
                                    authServiceInstance={this.props.authServiceInstance}
                                    apiServiceInstance={this.props.apiServiceInstance} />} 
                                />
                                <Route path='/tools' element={<Tools apiServiceInstance={this.props.apiServiceInstance} email={this.props.authServiceInstance.account.username} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </Router>
        );
      }
      else {
        return (
            <div className="App">
                <NotSignedIn
                    authServiceInstance={this.props.authServiceInstance} 
                    loginButtonClicked={this.invokeSignInEvent} />
            </div>
        );
      }
  }
}
export default App;
